import { Clue } from "./clue";
import { Row, RowState } from "./Row";
import { maxGuesses } from "./util";

export function About() {
  return (
    <div className="App-about">
      <p>
        <i>iilau iuleu</i> is a variation on the word game{" "}
        <a href="https://www.powerlanguage.co.uk/wordle/">
          <i>Wordle</i>
        </a>{" "}
        by <a href="https://twitter.com/powerlanguish">powerlanguage</a>, forked from <a href="https://github.com/lynn/hello-wordl">Hello Wordl</a>, by <a href="https://twitter.com/chordbug">Lynn</a>. In this version, words are taken from the <a href="https://teket-lau.neocities.org/imasa-iilau.html">Teket Lau dictionary</a>, rather than English.
      </p>
      <p>
        You get {maxGuesses} tries to guess a target word.
        <br />
        After each guess, you get Mastermind-style feedback.
      </p>
      <hr />
      <Row
        rowState={RowState.LockedIn}
        wordLength={4}
        cluedLetters={[
          { clue: Clue.Absent, letter: "i" },
          { clue: Clue.Absent, letter: "r" },
          { clue: Clue.Correct, letter: "u" },
          { clue: Clue.Elsewhere, letter: "l" },
        ]}
      />
      <p>
        <b>I</b> and <b>R</b> aren't in the target word at all.
      </p>
      <p>
        <b className={"green-bg"}>U</b> is correct! The third letter is{" "}
        <b className={"green-bg"}>U</b>
        .<br />
        <strong>(There may still be a second U in the word.)</strong>
      </p>
      <p>
        <b className={"yellow-bg"}>L</b> occurs <em>elsewhere</em> in the target
        word.
        <br />
        <strong>(Perhaps more than once. 🤔)</strong>
      </p>
      <hr />
      <p>
        Let's move the <b>L</b> in our next guess:
      </p>
      <Row
        rowState={RowState.LockedIn}
        wordLength={4}
        cluedLetters={[
          { clue: Clue.Correct, letter: "l" },
          { clue: Clue.Correct, letter: "a" },
          { clue: Clue.Correct, letter: "u" },
          { clue: Clue.Absent, letter: "n" },
        ]}
        annotation={"So close!"}
      />
      <Row
        rowState={RowState.LockedIn}
        wordLength={4}
        cluedLetters={[
          { clue: Clue.Correct, letter: "l" },
          { clue: Clue.Correct, letter: "a" },
          { clue: Clue.Correct, letter: "u" },
          { clue: Clue.Correct, letter: "t" },
        ]}
        annotation={"Got it!"}
      />
      <p><hr />
Contact <a href="mailto:mgerman@roastrabbit.com">mgerman@roastrabbit.com</a> with any questions or concerns</p>
    </div>
  );
}
